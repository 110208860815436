import { dispatch } from "../observer";

// Component state.
let isPlaying = false;

const handleKeyDown = (e) => {
  // Bail if the key pressed isn't the space-bar.
  if (e.key !== " ") return;

  e.preventDefault();

  if (isPlaying) {
    dispatch("AVP_PAUSE");
    isPlaying = false;
  } else {
    dispatch("AVP_PLAY");
    isPlaying = true;
  }
};

/**
 * Play/Pause button
 * @param {HTMLElement} shadowRoot
 */
export default (shadowRoot) => {
  const trigger = shadowRoot.querySelector(
    ".accessible-video-player__initial-play-button-wrapper"
  );

  trigger.addEventListener("keydown", handleKeyDown);
};
