import { tooltipOnHoverAndFocus } from "./tooltip";

let memorizedFocusElement;

const close =
  ({ transcriptContainer, trigger }) =>
  (event) => {
    transcriptContainer.setAttribute("aria-hidden", "true");
    trigger.setAttribute("aria-expanded", "false");

    memorizedFocusElement.focus();
    memorizedFocusElement = null;
  };

const open =
  ({ transcriptContainer, trigger, shadowRoot, focus }) =>
  (event) => {
    memorizedFocusElement = shadowRoot.activeElement;

    transcriptContainer.setAttribute("aria-hidden", "false");
    trigger.setAttribute("aria-expanded", "true");

    focus.focus();
  };

/**
 * Transcript button
 * @param {HTMLElement} shadowRoot
 */
export default (shadowRoot) => {
  const trigger = shadowRoot.querySelector(
    ".accessible-video-player__button--transcribe-button"
  );
  const closeButton = shadowRoot.querySelector(
    ".accessible-video-player__transcript-close"
  );
  const transcriptContainer = shadowRoot.querySelector(
    ".accessible-video-player__transcript"
  );

  trigger.addEventListener(
    "click",
    open({
      transcriptContainer,
      trigger,
      shadowRoot,
      focus: closeButton,
    })
  );
  closeButton.addEventListener(
    "click",
    close({ transcriptContainer, trigger })
  );

  const tooltipElement = trigger.querySelector(
    ".accessible-video-player__button-hover-target"
  );
  tooltipOnHoverAndFocus(trigger, tooltipElement, "transcript");
};

/**
 * Add transcription to template based on data.
 *
 * @param {HTMLElement} template
 * @param {Array} trancription
 * @returns {HTMLElement}
 */
export function addTranscriptToTemplate(template, transcript) {
  if (!transcript) {
    const videoContainer = template.querySelector(
      ".accessible-video-player__inner"
    );
    const trancribeButton = videoContainer.querySelector(
      ".accessible-video-player__button--transcribe-button"
    ).parentElement;

    trancribeButton.setAttribute("aria-hidden", true);

    return template;
  }

  const transcriptText = template.querySelector(
    ".accessible-video-player__transcript-text"
  );

  if (transcript) {
    transcriptText.innerHTML = transcript;
  }

  return template;
}
