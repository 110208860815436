import { dispatch, observe } from "../observer";

/**
 * Hide the play button.
 */
const handlePlay =
  ({ button }) =>
  () => {
    button.setAttribute("aria-hidden", "true");
    button.setAttribute("tabindex", "-1");
  };

/**
 * Show the play button.
 */
const handlePause =
  ({ button }) =>
  () => {
    button.setAttribute("aria-hidden", "false");
    button.setAttribute("tabindex", "0");
  };

const handleClick = () => {
  dispatch("AVP_PLAY");
  dispatch("AVP_SHIFT_FOCUS", { target: "play/pause" });
};

/**
 * Play/Pause button
 * @param {HTMLElement} shadowRoot
 */
export default (shadowRoot) => {
  const trigger = shadowRoot.querySelector(
    ".accessible-video-player__initial-play-button"
  );

  trigger.addEventListener("click", handleClick);

  observe("AVP_PLAY", handlePlay({ button: trigger }));
  observe("AVP_PAUSE", handlePause({ button: trigger }));
  observe("AVP_VIDEO_HAS_FINISHED", handlePause({ button: trigger }));
};
