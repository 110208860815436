import { observe, dispatch } from "../observer";

/**
 * Format time with leading zero.
 */
const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);

  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

/**
 * Add default data to the progress indicator.
 */
const handleLoadedMetaData =
  ({ progressIndicator, currentTimeIndicator, maxTimeIndicator }) =>
  ({ duration }) => {
    // -1 is used to align the progress bar with end. Because it starts with 0.
    progressIndicator.setAttribute("max", Math.ceil(duration - 1));
    currentTimeIndicator.innerHTML = "0:00";
    maxTimeIndicator.innerHTML = formatTime(duration);
  };

/**
 * Update data of the progress indicator.
 */
const handleProgressUpdate =
  ({ progressIndicator, currentTimeIndicator }) =>
  ({ currentTime }) => {
    progressIndicator.value = currentTime;
    currentTimeIndicator.innerHTML = formatTime(currentTime);

    // Update CSS variable for the progress bar (--progress-indicator-value).
    progressIndicator.style.setProperty(
      "--progress-indicator-value",
      `${(currentTime / progressIndicator.max) * 100}%`
    );
  };

let isPlaying = false;

const handleKeyDown =
  ({ stepValue }) =>
  (event) => {
    // Pause the video when space is clicked on the progress bar
    if (event.keyCode === 32) {
      event.preventDefault();
      if (isPlaying) {
        dispatch("AVP_PAUSE");
        isPlaying = false;
      } else {
        dispatch("AVP_PLAY");
        isPlaying = true;
      }
    }

    // Clicked on right arrow.
    if (event.keyCode === 39) {
      dispatch("AVP_FORWARD", { amountOfSeconds: stepValue });
    }

    // Clicked on left arrow.
    if (event.keyCode === 37) {
      dispatch("AVP_REWIND", { amountOfSeconds: stepValue });
    }
  };

/**
 * Change value on release of the progress indicator.
 */
const handleChange =
  ({ progressIndicator }) =>
  (event) => {
    dispatch("AVP_UPDATE_VIDEO_PROGRESS", {
      timestamp: Number(progressIndicator.value),
    });
    dispatch("AVP_UPDATE_PROGRESS_INDICATOR", {
      currentTime: Number(progressIndicator.value),
    });
  };

/**
 * Visually update the progress indicator without dispatching the new time.
 * This is done with the change handler.
 */
const visuallyUpdateProgressIndicator =
  ({ progressIndicator }) =>
  (event) => {
    const currentTime = Number(progressIndicator.value);
    // Update CSS variable for the progress bar (--progress-indicator-value).
    progressIndicator.style.setProperty(
      "--progress-indicator-value",
      `${(currentTime / progressIndicator.max) * 100}%`
    );
  };

/**
 * Play/Pause button
 * @param {HTMLElement} shadowRoot
 */
export default (shadowRoot) => {
  const progressIndicator = shadowRoot.querySelector(
    ".accessible-video-player__progress-bar"
  );
  const currentTimeIndicator = shadowRoot.querySelector(
    ".accessible-video-player__current-time"
  );
  const maxTimeIndicator = shadowRoot.querySelector(
    ".accessible-video-player__max-time"
  );
  const stepValue = progressIndicator.getAttribute("step");

  progressIndicator.addEventListener("keydown", handleKeyDown({ stepValue }));
  progressIndicator.addEventListener(
    "change",
    handleChange({ progressIndicator })
  );
  // Handle change for each step.
  progressIndicator.addEventListener(
    "input",
    visuallyUpdateProgressIndicator({ progressIndicator })
  );

  observe(
    "AVP_LOADED_META_DATA",
    handleLoadedMetaData({
      progressIndicator,
      currentTimeIndicator,
      maxTimeIndicator,
    })
  );
  observe(
    "AVP_UPDATE_PROGRESS_INDICATOR",
    handleProgressUpdate({
      progressIndicator,
      currentTimeIndicator,
    })
  );
};
