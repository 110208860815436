import { dispatch, observe } from "../observer";

let isPlaying = false;

const handlePlay =
  ({ trigger, triggerScreenReaderLabel, playIcon, pauseIcon }) =>
  () => {
    trigger.setAttribute("aria-hidden", "true");
    trigger.setAttribute("aria-pressed", "true");
    playIcon.setAttribute("aria-hidden", "true");
    pauseIcon.setAttribute("aria-hidden", "false");
    triggerScreenReaderLabel.textContent = "Pauzeren";
    isPlaying = true;
  };
const handlePause =
  ({ trigger, triggerScreenReaderLabel, playIcon, pauseIcon }) =>
  () => {
    trigger.setAttribute("aria-hidden", "false");
    trigger.setAttribute("aria-pressed", "false");
    playIcon.setAttribute("aria-hidden", "false");
    pauseIcon.setAttribute("aria-hidden", "true");
    triggerScreenReaderLabel.textContent = "Afspelen";
    isPlaying = false;
  };

const handleClick = () => {
  if (isPlaying) {
    dispatch("AVP_PAUSE");
  } else {
    dispatch("AVP_PLAY");
  }
};

/**
 * Shift focus to the play/pause button
 */
const handleShiftFocus =
  ({ trigger }) =>
  ({ target }) => {
    if (target !== "play/pause") return;
    trigger.focus();
  };

/**
 * Play/Pause button
 * @param {HTMLElement} shadowRoot
 */
export default (shadowRoot) => {
  const trigger = shadowRoot.querySelector(
    ".accessible-video-player__button--play-pause"
  );
  const triggerScreenReaderLabel = trigger.querySelector(
    ".js-accessible-video-player__button__screen-reader-label"
  );

  const playIcon = trigger.querySelector("[data-icon='play']");
  const pauseIcon = trigger.querySelector("[data-icon='pause']");

  trigger.addEventListener("click", handleClick);

  observe(
    "AVP_PAUSE",
    handlePause({ trigger, triggerScreenReaderLabel, playIcon, pauseIcon })
  );
  observe(
    "AVP_PLAY",
    handlePlay({ trigger, triggerScreenReaderLabel, playIcon, pauseIcon })
  );
  observe(
    "AVP_VIDEO_HAS_FINISHED",
    handlePause({ trigger, triggerScreenReaderLabel, playIcon, pauseIcon })
  );
  observe("AVP_SHIFT_FOCUS", handleShiftFocus({ trigger }));
};
