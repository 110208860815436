import { dispatch } from "../observer";
import { tooltipOnHoverAndFocus } from "./tooltip";

let cachedListener = null;

const handleFullScreenChange = (trigger) => (event) => {
  // The video is going full screen, no need to take action.
  if (document.fullscreenElement) return;

  // When the full-screen mode is closed.
  // Put focus on the initial open button.
  trigger.focus();

  // Remove the event listener.
  window.removeEventListener("fullscreenchange", cachedListener);
  cachedListener = null;
};

/**
 * Open video in full screen.
 */
const handleClick = (trigger) => () => {
  dispatch("AVP_FULL_SCREEN");

  // Move focus back when the full-screen video is closed.
  cachedListener = handleFullScreenChange(trigger);
  window.addEventListener("fullscreenchange", cachedListener);
};

/**
 * Play/Pause button
 * @param {HTMLElement} shadowRoot
 */
export default (shadowRoot) => {
  const trigger = shadowRoot.querySelector("#full-screen");

  trigger.addEventListener("click", handleClick(trigger));

  const tooltipElement = trigger.querySelector(
    ".accessible-video-player__button-hover-target"
  );
  tooltipOnHoverAndFocus(trigger, tooltipElement, "full-screen");
};
