import { dispatch, observe } from "../observer";

const closeOnEscape = (event) => {
  if (event.key !== "Escape") return;

  dispatch("AVP_CLOSE_HOVER_ELEMENT");
  window.removeEventListener("keydown", closeOnEscape);
};

const openTooltip =
  ({ target, itemId }) =>
  ({ id }) => {
    if (id === itemId) {
      target.setAttribute("data-visible", "true");
      window.addEventListener("keydown", closeOnEscape);
    } else {
      // Close when another tooltip opens.
      target.setAttribute("data-visible", "false");
    }
  };

const closeTooltip =
  ({ target }) =>
  () => {
    target.setAttribute("data-visible", "false");
  };

const notifyOpenTooltip = (id) => (event) =>
  dispatch("AVP_OPEN_HOVER_ELEMENT", { id });
const notifyCloseTooltip = (event) => dispatch("AVP_CLOSE_HOVER_ELEMENT");

const onLoad =
  ({ onHover = false, onFocus = false }) =>
  (trigger, target, id) => {
    if (onHover) {
      trigger.addEventListener("mouseenter", notifyOpenTooltip(id));
      trigger.addEventListener("mouseleave", notifyCloseTooltip);
    }

    if (onFocus) {
      trigger.addEventListener("focus", notifyOpenTooltip(id));
      trigger.addEventListener("blur", notifyCloseTooltip);
    }

    // Observers
    observe("AVP_OPEN_HOVER_ELEMENT", openTooltip({ target, itemId: id }));
    observe("AVP_CLOSE_HOVER_ELEMENT", closeTooltip({ target }));
  };

export const tooltipOnHover = (trigger, target, id) =>
  onLoad({ onHover: true })(trigger, target, id);
export const tooltipOnFocus = (trigger, target, id) =>
  onLoad({ onFocus: true })(trigger, target, id);
export const tooltipOnHoverAndFocus = (trigger, target, id) =>
  onLoad({ onHover: true, onFocus: true })(trigger, target, id);
