import { dispatch, observe } from "../observer";

// Component state.
let videoIsMuted = false;

const handleClick = () => (event) => {
  if (videoIsMuted) {
    dispatch("AVP_UNMUTE");
  } else {
    dispatch("AVP_MUTE");
  }
};

const handleUnmute =
  ({ trigger, muteIcon, unmuteIcon }) =>
  () => {
    videoIsMuted = false;
    trigger.setAttribute("aria-label", "Geluid uit");
    trigger.setAttribute("aria-pressed", "true");
    muteIcon.setAttribute("aria-hidden", "true");
    unmuteIcon.setAttribute("aria-hidden", "false");
  };

const handleMute =
  ({ trigger, muteIcon, unmuteIcon }) =>
  () => {
    videoIsMuted = true;
    trigger.setAttribute("aria-label", "Geluid aan");
    trigger.setAttribute("aria-pressed", "false");
    muteIcon.setAttribute("aria-hidden", "false");
    unmuteIcon.setAttribute("aria-hidden", "true");
  };

/**
 * Open volume slider on hover
 */
const handleMouseEnter = (event) => {
  dispatch("AVP_OPEN_VOLUME_SLIDER", { type: "volume" });
};

/**
 * Update the volume slider
 */
const handleAdjustVolume =
  ({ trigger, muteIcon, unmuteIcon }) =>
  ({ volume }) => {
    if (volume === 0) {
      handleMute({ trigger, muteIcon, unmuteIcon })();
    } else {
      handleUnmute({ trigger, muteIcon, unmuteIcon })();
    }
  };

/**
 * Play/Pause button
 * @param {HTMLElement} shadowRoot
 */
export default (shadowRoot) => {
  const trigger = shadowRoot.querySelector(
    ".accessible-video-player__button--mute-button"
  );
  const muteIcon = trigger.querySelector(".js-mute-icon");
  const unmuteIcon = trigger.querySelector(".js-unmute-icon");

  trigger.addEventListener("click", handleClick(), false);
  trigger.addEventListener("mouseenter", handleMouseEnter, false);

  observe("AVP_UNMUTE", handleUnmute({ trigger, muteIcon, unmuteIcon }));
  observe("AVP_MUTE", handleMute({ trigger, muteIcon, unmuteIcon }));

  // Ensure the button is unmuted when the volume is adjusted.
  observe(
    "AVP_ADJUST_VOLUME",
    handleAdjustVolume({ trigger, muteIcon, unmuteIcon })
  );
};
