import { dispatch, observe } from "../observer";
import { tooltipOnHover } from "./tooltip";

let volume = 100;

let cachedKeydownEventListener = null;

const handleKeyDown =
  ({ trigger }) =>
  (event) => {
    if (event.key === "Escape") {
      dispatch("AVP_CLOSE_VOLUME_SLIDER", { focusElement: trigger });
    }
  };

const handleMouseLeave = (event) => {
  dispatch("AVP_CLOSE_VOLUME_SLIDER");
};
const handleBlur = (event) => {
  dispatch("AVP_CLOSE_VOLUME_SLIDER");
};

const handleInput =
  ({ slider }) =>
  () => {
    volume = parseInt(slider.value, 10);

    dispatch("AVP_ADJUST_VOLUME", { volume: volume / 100 });
    slider.style.setProperty("--volume-slider-value", `${volume}%`);
  };

const handleMute =
  ({ slider }) =>
  () => {
    slider.value = 0;
    slider.style.setProperty("--volume-slider-value", `0%`);
  };
const handleUnmute =
  ({ slider }) =>
  () => {
    // Reset volume to the previous value.
    slider.value = volume;
    slider.style.setProperty("--volume-slider-value", `${volume}%`);
  };

/**
 * Open volume slider when a user focuses on it and it's not yet open.
 */
const handleFocus = (event) => {
  dispatch("AVP_OPEN_VOLUME_SLIDER", { type: "volume" });
};

/**
 * Close volume slider.
 */
const closeVolumeSlider =
  ({ container }) =>
  ({ focusElement } = {}) => {
    container.setAttribute("data-state", "closed");

    if (focusElement) {
      focusElement.focus();
    }
  };
const openVolumeSlider =
  ({ container, trigger }) =>
  () => {
    container.setAttribute("data-state", "active");

    cachedKeydownEventListener = handleKeyDown({ trigger });
    container.addEventListener("keydown", cachedKeydownEventListener);
  };

/**
 * Play/Pause button.
 *
 * @param {HTMLElement} shadowRoot
 */
export default (shadowRoot) => {
  const trigger = shadowRoot.querySelector(
    ".accessible-video-player__button--mute-button"
  );
  const container = shadowRoot.querySelector(
    ".accessible-video-player__volume-container"
  );
  const slider = shadowRoot.querySelector(
    ".accessible-video-player__volume-slider"
  );
  const parent = shadowRoot.querySelector(
    ".accessible-video-player__volume-parent"
  );

  parent.addEventListener("mouseleave", handleMouseLeave);

  observe(
    "AVP_CLOSE_VOLUME_SLIDER",
    closeVolumeSlider({
      container,
    })
  );
  observe(
    "AVP_OPEN_VOLUME_SLIDER",
    openVolumeSlider({
      trigger,
      container,
    })
  );

  observe("AVP_UNMUTE", handleUnmute({ slider }));
  observe("AVP_MUTE", handleMute({ slider }));

  slider.addEventListener("focus", handleFocus);
  slider.addEventListener("blur", handleBlur);
  slider.addEventListener("input", handleInput({ slider }));

  // Set initial volume
  handleInput({ slider })();

  // Open volume-slider on hover.
  // @TODO: Fix this behavior! Could be a difficould one!
  tooltipOnHover(trigger, container, "volume");
};
