import { dispatch } from "../observer";
import { tooltipOnHoverAndFocus } from "./tooltip";

// Component state.
let showAudioDescription = false;

/**
 * Toggle the audio description on and off.
 */
const handleClick = (triggerLabel) => (event) => {
  event.preventDefault();

  if (showAudioDescription) {
    dispatch("AVP_USE_DEFAULT_VIDEO");
    showAudioDescription = false;
    triggerLabel.textContent = "Video met audiobeschrijving";
  } else {
    dispatch("AVP_USE_AUDIO_DESCRIPTION_VIDEO");
    showAudioDescription = true;
    triggerLabel.textContent = "Video zonder audiobeschrijving";
  }

  // Set the subtitles to off, no matter the state.
  dispatch("AVP_UPDATE_SUBTITLES", { id: "off" });
};

/**
 * Audio description button.
 * @param {HTMLElement} shadowRoot
 */
export default (shadowRoot) => {
  const trigger = shadowRoot.querySelector("#audio-description");
  const triggerLabel = trigger.querySelector(
    ".accessible-video-player__button-hover-target"
  );

  trigger.addEventListener("click", handleClick(triggerLabel));

  tooltipOnHoverAndFocus(trigger, triggerLabel, "audio-description");
};
