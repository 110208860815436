import { dispatch, observe } from "../observer";
import { tooltipOnHoverAndFocus } from "./tooltip";

let subtitlesPopupIsOpen = false;

const handleClick = (event) => {
  event.preventDefault();

  if (subtitlesPopupIsOpen) {
    dispatch("AVP_CLOSE_SUBTITLES");
  } else {
    dispatch("AVP_OPEN_SUBTITLES");
  }
};

const handleConfirmationClick =
  ({ item }) =>
  (event) => {
    event.preventDefault();
    const id = item.getAttribute("data-value");
    dispatch("AVP_UPDATE_SUBTITLES", { id });
    dispatch("AVP_CLOSE_SUBTITLES");
  };

let cachedKeydownEventListener = null;
let cachedMouseEventListener = null;

const handleKeyDown =
  ({ trigger }) =>
  (event) => {
    if (
      event.key === "Escape" ||
      event.key === "Tab" ||
      event.key === "Enter"
    ) {
      dispatch("AVP_CLOSE_SUBTITLES", { focusElement: trigger });
    }
  };

const handleOpenSubtitles =
  ({ container, trigger, shadowRoot }) =>
  () => {
    subtitlesPopupIsOpen = true;

    // Set state on container.
    container.setAttribute("data-state", "active");
    trigger.setAttribute("aria-expanded", "true");

    // Add tabindex to items within container.
    cachedKeydownEventListener = handleKeyDown({ trigger });

    const inputElements = Array.from(container.querySelectorAll("input"));
    inputElements.forEach((item) => {
      item.setAttribute("tabindex", "0");
      item.addEventListener("keydown", cachedKeydownEventListener);
    });

    inputElements[0].focus();

    cachedMouseEventListener = (event) => {
      if (!container.contains(event.target) && event.target !== trigger) {
        dispatch("AVP_CLOSE_SUBTITLES", { focusElement: trigger });
      }
    };
    // Place it on the shadowRoot and not the window.
    // If placed on the Window, the event.trigger will always be the Custom Element.
    // Use mousedown event, otherwise it will be triggered on keydown as well (no idea why!).
    shadowRoot.addEventListener("mousedown", cachedMouseEventListener, true);
  };
const handleCloseSubtitles =
  ({ container, shadowRoot, trigger }) =>
  ({ focusElement } = {}) => {
    subtitlesPopupIsOpen = false;

    // Set state on container.
    container.setAttribute("data-state", "closed");
    trigger.setAttribute("aria-expanded", "false");

    // Add tabindex to items within container.
    const inputElements = Array.from(container.querySelectorAll("input"));
    inputElements.forEach((item) => {
      item.setAttribute("tabindex", "-1");
      item.removeEventListener("keydown", cachedKeydownEventListener);
    });

    // Remove event listeners.
    shadowRoot.removeEventListener("mousedown", cachedMouseEventListener, true);

    // Reset cached event listeners.
    cachedKeydownEventListener = null;
    cachedMouseEventListener = null;

    if (!focusElement) return;
    // Shift focus back to trigger is too fast.
    // This will trigger a click event if not wrapped inside a setTimeout.
    setTimeout(() => {
      focusElement.focus();
    }, 100);
  };

const handleChangeSubtitles =
  ({ item }) =>
  ({ id }) => {
    item.setAttribute("data-checked", item.value === id);
  };

const handleChange = (event) => {
  const id = event.target.value;
  dispatch("AVP_UPDATE_SUBTITLES", { id });
};

/**
 * Update the subtitles.
 * Update the options in the select element.
 */
const updateSubtitles = (videoElement, container, subtitles) => {
  if (subtitles && subtitles.length) {
    // Add an option for no subtitles.
    const offLabelElement = document.createElement("label");
    offLabelElement.setAttribute("data-value", "off");
    offLabelElement.setAttribute("for", "off");
    offLabelElement.setAttribute("lang", "nl");
    offLabelElement.innerHTML = `<input id="off" value="off" name="subtitles" type="radio" checked tabindex="-1" data-checked="true"> <span aria-hidden="true">✓</span> <span>Uit</span>`;
    container.appendChild(offLabelElement);

    subtitles.forEach(({ label, srclang, src, isDefault }) => {
      // Add track to video
      const track = document.createElement("track");
      track.setAttribute("kind", "subtitles");
      track.setAttribute("label", label);
      track.setAttribute("srclang", srclang);
      track.setAttribute("src", src);
      if (isDefault) track.setAttribute("default", isDefault);
      videoElement.appendChild(track);

      // Add option to select
      const labelElement = document.createElement("label");
      labelElement.setAttribute("data-value", srclang);
      labelElement.setAttribute("for", srclang);
      labelElement.setAttribute("lang", srclang);
      labelElement.innerHTML = `<input id="${srclang}" value="${srclang}" name="subtitles" type="radio" tabindex="-1"> <span aria-hidden="true">✓</span> <span>${label}</span>`;
      container.appendChild(labelElement);
    });
  }
};

const addListenersToSubtitles = (optionsContainer) => {
  const labelElements = Array.from(optionsContainer.querySelectorAll("label"));
  const inputElements = Array.from(optionsContainer.querySelectorAll("input"));

  // Use label elements as they are clicked. The Checkboxes are visually hidden.
  labelElements.forEach((item) => {
    item.addEventListener("mousedown", handleConfirmationClick({ item }), true);
  });
  inputElements.forEach((item) => {
    item.addEventListener("change", handleChange);
    observe("AVP_UPDATE_SUBTITLES", handleChangeSubtitles({ item }));
  });
};

/**
 * Update the subtitles and update the list of possible languages.
 */
const handleUpdateSubtitles =
  ({ videoElement, labelsContainer, container, subtitles }) =>
  () => {
    // Remove all current tracks from video.
    const tracks = videoElement.querySelectorAll("track");
    tracks.forEach((track) => videoElement.removeChild(track));

    // Remove all current options from labelsContainer.
    Array.from(labelsContainer.querySelectorAll("label")).forEach((label) =>
      labelsContainer.removeChild(label)
    );

    updateSubtitles(videoElement, labelsContainer, subtitles);

    addListenersToSubtitles(container);
  };

/**
 * Subtitles.
 *
 * @param {HTMLElement} shadowRoot
 */
export default (
  shadowRoot,
  { subtitlesForDefaultVideo, subtitlesForAudioDescriptionVideo }
) => {
  const trigger = shadowRoot.querySelector(".js-subtitles-trigger");
  trigger.addEventListener("click", handleClick);

  const optionsContainer = shadowRoot.querySelector(".js-subtitles-options");
  addListenersToSubtitles(optionsContainer);

  observe(
    "AVP_OPEN_SUBTITLES",
    handleOpenSubtitles({ container: optionsContainer, trigger, shadowRoot })
  );
  observe(
    "AVP_CLOSE_SUBTITLES",
    handleCloseSubtitles({ container: optionsContainer, shadowRoot, trigger })
  );
  observe(
    "AVP_USE_AUDIO_DESCRIPTION_VIDEO",
    handleUpdateSubtitles({
      videoElement: shadowRoot.querySelector(".accessible-video-player__video"),
      container: shadowRoot.querySelector(".js-subtitles-options"),
      labelsContainer: shadowRoot.querySelector(
        ".js-subtitles-options-container"
      ),
      subtitles: subtitlesForAudioDescriptionVideo,
    })
  );
  observe(
    "AVP_USE_DEFAULT_VIDEO",
    handleUpdateSubtitles({
      videoElement: shadowRoot.querySelector(".accessible-video-player__video"),
      container: shadowRoot.querySelector(".js-subtitles-options"),
      labelsContainer: shadowRoot.querySelector(
        ".js-subtitles-options-container"
      ),
      subtitles: subtitlesForDefaultVideo,
    })
  );
  observe(
    "AVP_OPEN_HOVER_ELEMENT",
    handleCloseSubtitles({
      container: shadowRoot.querySelector(".js-subtitles-options"),
      shadowRoot,
      trigger,
    })
  );

  const tooltipElement = trigger.querySelector(
    ".accessible-video-player__button-hover-target"
  );
  tooltipOnHoverAndFocus(trigger, tooltipElement, "subtitles");
};

/**
 * Add subtitles to template based on data.
 *
 * @param {HTMLElement} template
 * @param {Array} subtitles
 * @returns {HTMLElement}
 */
export function addSubtitlesToTemplate(template, subtitles) {
  const videoElement = template.querySelector(
    ".accessible-video-player__video"
  );

  if (!subtitles) {
    const videoParent = videoElement.parentElement;

    const subtitlesButton = videoParent.querySelector(
      ".js-subtitles-container"
    );

    subtitlesButton.setAttribute("aria-hidden", true);

    return template;
  }

  const container = template.querySelector(".js-subtitles-options-container");
  updateSubtitles(videoElement, container, subtitles);

  return template;
}
