import playPause from "./play-pause";
import muteUnmute from "./mute-unmute";
import fullScreen from "./full-screen";
import initialPlay from "./initial-play";
import volumeSlider from "./volume-slider";
import audioDescription from "./audio-description";
import progressIndicator from "./progress-indicator";
import playPauseKeyControl from "./play-pause-key-control";
import videoPlayer, { addVideoToTemplate } from "./video-player";
import subtitles, { addSubtitlesToTemplate } from "./subtitles";
import transcript, { addTranscriptToTemplate } from "./transcript";

/**
 * Accessible video player.
 *
 * This is a Custom Element, therefore, it's a class.
 * To ensure a loosely coupled component (and use the class syntax only for the base of the Custom Element),
 * we use the observer pattern to communicate between the different modules of this Custom Element.
 */
class AccessibleVideoPlayer extends HTMLElement {
  static observedAttributes = ["data-subtitles"];

  constructor() {
    super();

    const template = document.getElementById(
      "accessible-video-player-template"
    );
    const templateContent = template.content;

    // sets and returns 'this.shadowRoot'
    const shadowRoot = this.attachShadow({ mode: "open" });
    const clone = templateContent.cloneNode(true);

    this.props = this.getData();

    /**
     * Add HTML from specific components to the template before it's added to the shadow root.
     * @TODO: Make this chainable
     */
    const cloneWithTranscript = addTranscriptToTemplate(
      clone,
      this.props.transcriptContent
    );
    const cloneWithSubtitles = addSubtitlesToTemplate(
      cloneWithTranscript,
      this.props.defaultSubtitles
    );
    const cloneWithVideo = addVideoToTemplate(cloneWithSubtitles, {
      audioDescriptionVideoSrc: this.props.audioDescriptionVideoSrc,
      defaultVideoSrc: this.props.defaultVideoSrc,
      posterSrc: this.props.posterSrc,
      title: this.props.title,
    });

    // When all content is added to the template, append it to the shadow root.
    shadowRoot.appendChild(cloneWithVideo);

    // Initialize.
    this.initializeVideoPlayer();
    this.initializeObservers();

    // State
    // @TODO: Check if this is the way to go!?
    this.hasPlayedOnce = false;
    this.videoIsPlaying = false;
  }

  initializeVideoPlayer = () => {
    this.videoElement = this.shadowRoot.querySelector(
      ".accessible-video-player__video"
    );
  };

  getData() {
    const subtitlesData = this.getAttribute("data-default-subtitles");
    const videoDescriptionSubtitlesData = this.getAttribute(
      "data-video-description-subtitles"
    );
    const defaultVideoSrcData = this.getAttribute("data-default-video-src");
    const audioDescriptionVideoSrcData = this.getAttribute(
      "data-audio-description-video-src"
    );

    return {
      defaultSubtitles: JSON.parse(subtitlesData),
      audioDescriptionSubtitles: JSON.parse(videoDescriptionSubtitlesData),
      transcriptContent: this.getAttribute("data-transcript"),
      posterSrc: this.getAttribute("data-poster"),
      defaultVideoSrc: JSON.parse(defaultVideoSrcData),
      audioDescriptionVideoSrc: JSON.parse(audioDescriptionVideoSrcData),
      title: this.getAttribute("data-title"),
    };
  }

  initializeObservers = () => {
    fullScreen(this.shadowRoot);
    playPause(this.shadowRoot);
    playPauseKeyControl(this.shadowRoot);
    initialPlay(this.shadowRoot);
    muteUnmute(this.shadowRoot);
    progressIndicator(this.shadowRoot);
    transcript(this.shadowRoot);
    volumeSlider(this.shadowRoot);
    videoPlayer(this.videoElement);
    subtitles(this.shadowRoot, {
      subtitlesForDefaultVideo: this.props.defaultSubtitles,
      subtitlesForAudioDescriptionVideo: this.props.audioDescriptionSubtitles,
    });
    audioDescription(this.shadowRoot);
  };
}

// Initialize function
export default () => {
  // Check if the browser supports custom elements
  if (!window.customElements) return;

  // Check if the browser supports the video element (could be overkill)
  const supportsVideo = !!document.createElement("video").canPlayType;
  if (!supportsVideo) return;

  // Define the new element
  customElements.define("accessible-video-player", AccessibleVideoPlayer);
};
